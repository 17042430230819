import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";
import Question from "../components/question/Question";
import NewQuestionsController from "./NewQuestions.controller";
import QuestionAlert from "../components/question/QuestionAlert";
import { useEffect, useRef } from "react";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";
import MaxCapitalModal from "../components/MaxCapitalModal/MaxCapitalModal";

const NewQuestions = (): JSX.Element => {
  const {
    isGetlifeBroker,
    actualQuestion,
    translate,
    saveQuestion,
    answeredQuestions,
    isEditActive,
    setIsEditActive,
    code,
    hasTelesubcription,
    navigate,
    toggleEditAnswer,
    loading,
    newCapital
  } = NewQuestionsController();

  const questionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (questionsRef?.current) {
      questionsRef.current.scrollTo({
        top: questionsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [questionsRef, actualQuestion]);

  return (
    <div className="max-w-[1280px] m-[0_auto] p-[32px] h-[calc(100%_-_80px)]">
      <div
        className="p-[24px_40px] bg-white rounded-lg h-full flex flex-col"
        style={{
          boxShadow:
            "0px -1px 0px 0px rgba(66, 66, 66, 0.10) inset, 0px 1px 3px 0px rgba(66, 66, 66, 0.10)",
        }}
      >
        <div className="text-[#424242] flex flex-row gap-[16px]">
          <GlobalIcon
            iconName="CheckupListIcon"
            size="XS"
            color="currentColor"
          />
          <div className="flex flex-col gap-[12px]">
            <p className="BodyL font-bold">
              {translate("question.page.mainTitle")}
            </p>
            <p className="BodyM">
              {translate("question.page.mainDescription")}
            </p>
          </div>
        </div>
        <div
          className="mt-[24px] rounded-[8px] bg-[#f5f7fa] p-[32px_24px] flex flex-col gap-[32px] flex-1 overflow-auto"
          ref={questionsRef}
        >
          <p className="text-[#424242] BodyL font-bold">
            {translate("question.page.personalQuestions")}
          </p>
          {answeredQuestions.length !== -1 &&
            answeredQuestions.map((question, index) => {
              return (
                <Question
                  key={index}
                  defaultAnswer={question?.value}
                  answered={true}
                  answers={question?.answers}
                  content={question?.content}
                  handleNextQuestion={saveQuestion}
                  placeholder={translate("questions.placeholder.select")}
                  questionId={question?.questionId}
                  type={question?.type}
                  componentName={question?.componentName}
                  question={question}
                  isNotEditable={
                    hasTelesubcription ||
                    !isGetlifeBroker &&
                    (question?.componentName === "external_underwriting" ||
                      question?.questionId === 588)
                  }
                  isEditActive={isEditActive}
                  setIsEditActive={setIsEditActive}
                  toggleEditAnswer={toggleEditAnswer}
                />
              );
            })}
          {loading ? (
            <div className="flex flex-row gap-[24px] items-center justify-end py-[24px] md:px-[64px] px-[24px]">
              <Spinner />
              <p className="BodyM text-[#000000] font-medium">
                {translate("question.page.loading")}
              </p>
            </div>
          ) : null}
          {actualQuestion && (
            <Question
              content={actualQuestion.content}
              type={actualQuestion.type}
              placeholder={translate("questions.placeholder.select")}
              answers={actualQuestion.answers}
              handleNextQuestion={saveQuestion}
              answered={false}
              questionId={actualQuestion.questionId}
              componentName={actualQuestion?.componentName}
              question={actualQuestion}
              isEditActive={isEditActive}
              setIsEditActive={setIsEditActive}
            />
          )}

          {actualQuestion === null &&
            answeredQuestions.length !== 0 &&
            code !== 0 && (
              <QuestionAlert telesubscription={hasTelesubcription} />
            )}
          {code === 0 && (
            <div>
              <ButtonDS
                onClick={() => navigate(`/checkpoint`)}
                label={translate("question.page.continue.button")}
                buttonType={"primary"}
                className="mx-auto"
                rightIcon="ArrowRightIcon"
                disabled={!!actualQuestion || loading === true}
              />
            </div>
          )}

          <MaxCapitalModal capital={newCapital}/>
        </div>
      </div>
    </div>
  );
};

export default NewQuestions;
